





































































































































































.container-css {
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;

  .item-content {
    .title {
      font-weight: 600;
      font-size: 14px;
      color: #333333;
      line-height: 14px;
      text-align: left;
      font-style: normal;
      text-transform: none;
      padding-top: 10px;
      padding-bottom: 12px;
    }
    .overview {
      padding-left: 8px;
      padding-bottom: 5px;
      font-weight: 400;
      font-size: 14px;
      color: #333333;
      line-height: 20px;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }
    .content {
      height: 200px;
      padding-left: 8px;
      overflow-x: hidden;
      overflow-y: auto;
      font-weight: 400;
      font-size: 14px;
      color: #333333;
      line-height: 20px;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }
  }
}

